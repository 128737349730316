import { svgIconClassName } from '@archipro-design/icons';

import type { StyleRule } from '@archipro-design/aria';

import {
    pxToRem,
    flexClassName,
    pxArrayToRem,
    captionClassName,
} from '@archipro-design/aria';

export const DisabledAccountContainer: StyleRule = ({ theme }) => ({
    width: '100%',

    '& *': {
        color: theme.siteVariables.colors.primitive.white,
    },

    background: theme.siteVariables.bodyBackground,

    [`& .${flexClassName}`]: {
        width: '100%',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: pxToRem(16),
        background: theme.siteVariables.colors.danger[200],

        [theme.screen.laptop]: {
            justifyContent: 'center',
            alignItems: 'center',
            padding: 0,
            height: pxToRem(56),
        },

        [`& .${captionClassName}`]: {
            lineHeight: 1.5,
            color: theme.siteVariables.colors.primitive.white,
        },
    },

    [`& .${svgIconClassName}`]: {
        width: pxToRem(24),
        height: pxToRem(24),
        marginRight: pxToRem(8.5),
        '& svg': {
            fill: theme.siteVariables.colors.primitive.white,
        },
    },

    [theme.screen.laptop]: {
        paddingTop: pxToRem(32),
        padding: pxArrayToRem([32, 50, 0]),
    },
});
