import { useSearchParams } from '@remix-run/react';
import { useEffect, useState } from 'react';
import type {
    DropdownItem,
    EnquiryProfessional,
} from '@modules/enquiry/component/enquiry-form/EnquiryForm';
import type { Branch } from '~/modules/enquiry/util/extract-branch-from-network.server';
import EnquiryModal from '~/modules/enquiry/component/enquiry-modal/EnquiryModal';
import { useAppDisplayModeContext } from '@archipro-design/aria';
import {
    ENQUIRE_AUTH_SOURCE_VALUE,
    ENQUIRE_MODAL_SHOWUP_KEY,
    ENQUIRE_MODAL_TYPO_KEY,
    ENQUIRE_MODAL_SHOWUP_KEY_VALUE as KEY_VALUE,
} from '~/modules/enquiry/constants';
import {
    AUTH_PROVIDER_PARAM_KEY,
    AUTH_SOURCE_PARAM_KEY,
} from '~/modules/root/hook/use-auth-event';

interface RenderEnquiryModalProps {
    professional: EnquiryProfessional;
    branchOptions?: DropdownItem<Branch>[];
    onOpenChange?: (open: boolean) => void;
}

const DOUBLE_CHECK_DELAY = 2000;

export const useEnquiryModalByUrl = (
    alwaysRenderModal = false,
    showModalByDefault = false
) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { mobile } = useAppDisplayModeContext();
    const [isOpen, setIsOpen] = useState(showModalByDefault);

    const deleteEnquiryModalShowUpKey = () => {
        if (searchParams.has(ENQUIRE_MODAL_SHOWUP_KEY)) {
            searchParams.delete(ENQUIRE_MODAL_SHOWUP_KEY);
            setSearchParams(searchParams, { preventScrollReset: true });
        }
    };

    const addEnquiryModalShowUpKey = () => {
        if (searchParams.get(ENQUIRE_MODAL_SHOWUP_KEY) !== KEY_VALUE) {
            searchParams.set(ENQUIRE_MODAL_SHOWUP_KEY, KEY_VALUE);
            setSearchParams(searchParams, { preventScrollReset: true });
        }
    };

    const handleOpenChange = (open: boolean) => {
        setIsOpen(open);
        if (!open) {
            deleteEnquiryModalShowUpKey();
            // in some weird test cases, e.g. clicking different links very quickly after the model closes, the first delete sometimes does not work
            // noticed there's a delay for url change, assume it's caused by other function, but can't find it
            // todo: find a better solution
            setTimeout(deleteEnquiryModalShowUpKey, DOUBLE_CHECK_DELAY);
        }
    };

    const renderEnquiryModal = ({
        professional,
        branchOptions,
        onOpenChange,
    }: RenderEnquiryModalProps) => {
        const newBranchOptions =
            branchOptions || professional.branchOptions || [];

        if (!alwaysRenderModal && !isOpen) {
            return null;
        }

        return (
            <EnquiryModal
                professional={professional}
                branchOptions={newBranchOptions}
                open={isOpen}
                onOpenChange={(open) => {
                    handleOpenChange(open);
                    onOpenChange?.(open);
                }}
                drawer={mobile}
            />
        );
    };

    const openEnquiryModal = (changeUrl = false) => {
        setIsOpen(true);
        if (changeUrl) {
            addEnquiryModalShowUpKey();
        }
    };

    const closeEnquiryModal = (changeUrl = false) => {
        setIsOpen(false);
        if (changeUrl) {
            deleteEnquiryModalShowUpKey();
        }
    };

    useEffect(() => {
        // if not contains ENQUIRE_MODAL_TYPO_KEY and ENQUIRE_MODAL_SHOWUP_KEY,  do nothing
        if (
            !searchParams.has(ENQUIRE_MODAL_TYPO_KEY) &&
            !searchParams.has(ENQUIRE_MODAL_SHOWUP_KEY)
        ) {
            return;
        }
        // redirect after social login from enquiry-modal, do nothing
        if (
            searchParams.has(AUTH_PROVIDER_PARAM_KEY) &&
            searchParams.get(AUTH_SOURCE_PARAM_KEY) ===
                ENQUIRE_AUTH_SOURCE_VALUE
        ) {
            return;
        }

        // if ENQUIRE_MODAL_TYPO_KEY exits, replace it with correct key
        if (searchParams.get(ENQUIRE_MODAL_TYPO_KEY) === KEY_VALUE) {
            searchParams.delete(ENQUIRE_MODAL_TYPO_KEY);
            searchParams.set(ENQUIRE_MODAL_SHOWUP_KEY, KEY_VALUE);
            setSearchParams(searchParams, { preventScrollReset: true });
        }

        const showEnquiry =
            searchParams.get(ENQUIRE_MODAL_SHOWUP_KEY) === KEY_VALUE;
        setIsOpen(showEnquiry);
    }, [searchParams, setSearchParams]);

    return {
        renderEnquiryModal,
        openEnquiryModal,
        closeEnquiryModal,
        isEnquiryModalOpen: isOpen,
        isMobile: mobile,
        searchParams,
        setSearchParams,
    };
};
