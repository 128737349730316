import { Caption, Flex, useStyles } from '@archipro-design/aria';

import { ErrorWarningFill } from '@archipro-design/icons';

import * as S from './DisabledAccount.style';

const DisabledAccount = () => {
    const { css } = useStyles();

    return (
        <div className={`${css(S.DisabledAccountContainer)}`}>
            <Flex vAlign="center" hAlign="center">
                <ErrorWarningFill />
                <Caption variant="03">
                    All pages for this company are disabled and only viewable &
                    searchable by ArchiPro admins.
                </Caption>
            </Flex>
        </div>
    );
};

export default DisabledAccount;
